import React from "react"
import Layout from "../containers/Layout"
import BoxCollection from "../containers/BoxCollection"
import Box from "../components/Box"
import boxColors from "../styles/box-colors.module.scss"

export default () => (
  <Layout>
    <BoxCollection title="Slides" colorClassName={boxColors.red}>
      <Box link="/slides/generative-art" label="Generative Art Workshop" />
      <Box link="/slides/divoc-datenethik-smart-city" label="Datenethik in der »Smart City«" />
    </BoxCollection>
  </Layout>
)
